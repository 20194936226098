// src/api/axios.js
import axios from 'axios';

const BASE_URL = 'https://devbackend.rgsgroceries.com'
const LOCAL_BASE_URL = 'http://localhost:3001'

const LOCAL_APP_URL = `${LOCAL_BASE_URL}/api/v1`
const APP_URL = `${BASE_URL}/api/v1`

const instance = axios.create({
  baseURL: APP_URL, // Use environment variables for API base URL
  timeout: 5000, 
});

// Add a request interceptor to include authentication tokens
// instance.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem('token'); // Get token from localStorage (or cookies)
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );


export const createModuleInstance = (moduleRoute) => {
  return axios.create({
    baseURL: `${APP_URL}/${moduleRoute}`,
    timeout: 5000,
  });
};

export default instance;
