import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {fetchAllMinimalProducts, createNewMinimalProduct, fetchSingleMinimalProduct, deleteMinimalProduct, updateMinimalProduct} from '../../api/minimalProductApi';
import { useDispatch } from 'react-redux';
import { showNotification } from '../common/headerSlice';


// CRUD Thunks

// fetch all minimal products
export const fetchAlMinimallProductsThunk = createAsyncThunk(
    'products/fetchAllMinimalProducts',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchAllMinimalProducts();
            console.log(response.data)
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "failed to fetch products");
        }
    }
);


// create new minimal product
export const createNewMinimalProductThunk = createAsyncThunk(
    'products/createProduct',
    async (productData, { rejectWithValue }) => {
        try {
            const response = await createNewMinimalProduct(productData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message || "failed to create a minimal product");
        }
    }
);

// fetch single minimal product
export const fetchSingleMinimalProductThunk = createAsyncThunk(
    'products/fetchSingleMinimalProduct',
    async (productId, { rejectWithValue }) => {
        try {
            const response = await fetchSingleMinimalProduct(productId);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "failed to fetch minimal products")
        }
    }
);

// delete a minimal product
export const deleteMinimalProductThunk = createAsyncThunk(
    'products/deleteMinimalProduct',
    async (productId, { rejectWithValue }) => {
        try {
            const response = await deleteMinimalProduct(productId);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "failed to delete a minimal product");
        }
    }
);

// update a product
export const updateMinimalProductThunk = createAsyncThunk(
    'products/updateminimalProduct',
    async ({productId, updatedData}, { rejectWithValue }) => {
        console.log(productId, updatedData)
        try {
            const response = await updateMinimalProduct(productId, updatedData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "failed to update minimal product")
        }
    }
);


const minimalProductSlice = createSlice({
    name: 'minimalProducts',
    initialState: {
        minimalProducts: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Handle fetch
            .addCase(fetchAlMinimallProductsThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAlMinimallProductsThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.minimalProducts = action.payload.data;
            })
            .addCase(fetchAlMinimallProductsThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // Handle create
            .addCase(createNewMinimalProductThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(createNewMinimalProductThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.minimalProducts.push(action.payload.data);
                console.log(action.payload)
                showNotification({ message: action.payload.message, status: 1 });
            })
            .addCase(createNewMinimalProductThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                showNotification({ message: action.payload.message, status: 0 });
            })

            // Handle update
            .addCase(updateMinimalProductThunk.pending, (state) => {
                state.loading = true;
                console.log("updating...")
            })
            .addCase(updateMinimalProductThunk.fulfilled, (state, action) => {
                console.log("updated...")

                state.loading = false;
                const index = state.minimalProducts.findIndex((minimal_product) => minimal_product._id === action.payload._id);
                if (index !== -1) {
                    state.minimalProducts[index] = action.payload;
                }
            })
            .addCase(updateMinimalProductThunk.rejected, (state, action) => {
                console.log("failed...")

                state.loading = false;
                state.error = action.payload;
            })

            // Handle delete
            .addCase(deleteMinimalProductThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteMinimalProductThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.minimalProducts = state.minimalProducts.filter((minimal_product) => minimal_product._id !== action.payload.data._id);
            })
            .addCase(deleteMinimalProductThunk.rejected, (state, action) => {
                console.log("failed to delete")
                state.loading = false;
                state.error = action.payload;
            });

    },
});


export default minimalProductSlice.reducer;
